<template>
  <v-container>
    <v-row align="center" justify="space-around">
      <div class="text--headline">
        Looks like this page hasn't been created yet.
        <span class="grey--text">Redirecting...</span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.push("/");
    }, 3000);
  }
};
</script>
